import React from 'react'
import { Button } from 'semantic-ui-react'
import { AmplifySignOut } from '@aws-amplify/ui-react'

class NavView extends React.Component
{
    render()
    {
        return (
            <div>
                <Button floated="left" size="large" color="green">#propdontshop</Button>
                <Button.Group floated="right">
                    <Button primary>Home</Button>
                    <Button>Account</Button>
                    <AmplifySignOut />
                </Button.Group>
            </div>
        );
    }
}

export default NavView;