import React from 'react'
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'

class LoaderView extends React.Component
{
    constructor(props)
    {
        super(props);
        this.text = props.text;
    }

    render()
    {
        return (
            <div>
                <Segment>
                <Dimmer active>
                    <Loader>{this.text ? this.text : "Loading"}</Loader>
                </Dimmer>

                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
            </div>
        )
    }
}

export default LoaderView