/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://lgahga2w25drngpqcalvyb7buq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:022e623d-b44e-4c24-bf96-8caf61a2bd6f",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1stA7ZOGy",
    "aws_user_pools_web_client_id": "2lqrvrgf3eojbar5qnt9uke9vn",
    "oauth": {},
    "aws_user_files_s3_bucket": "propdontshop608a65c9d24140efb9e6c62e2734d9b6143639-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
