import './App.css';
import 'semantic-ui-css/semantic.min.css';

import { withAuthenticator } from '@aws-amplify/ui-react'

import NavView from "./components/NavView"
import ListingsView from "./components/ListingsView"

function App() {
    return (
        <div className="App">
            <NavView horizontal/>
            <ListingsView />
        </div>
    );
}

export default withAuthenticator(App);
