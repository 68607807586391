import React, {useEffect, useState} from 'react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import LoaderView from './LoaderView'
import { Storage } from 'aws-amplify';
import ListingCardView from './ListingCardView'

const ListingModal = (props) =>
{
    // console.log(props);
    const [open, setOpen] = useState(false);
    let listing = props.listing;
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger=
            {
                <Button>
                    <ListingCardView name={listing.name} description={listing.description} image={listing.image} dateCreated={listing.createdAt} score={listing.score} />
                </Button>
            }
        >
            <Modal.Content image>
                { (!listing.image || listing.image === '') && <LoaderView text="Loading Image..." /> }
                { listing.image && <Image size='medium' src={listing.image} wrapped /> }
                <Modal.Description>
                    <Header size="huge">{listing.name}</Header>
                    <p> {listing.description} </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>Cancel</Button>
                <Button content="Submit" labelPosition='right' icon='checkmark' onClick={() => setOpen(false)} positive />
            </Modal.Actions>
        </Modal>
    )
}

export default ListingModal