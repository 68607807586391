import React, { useEffect, useState } from 'react'
import { Grid, Dropdown, Icon, Button, Menu, Segment } from 'semantic-ui-react'
import ListingCardView from './ListingCardView'
import LoaderView from './LoaderView'
import CreateItemModal from '../screens/CreateItemModal'
import axios from 'axios';
import config from '../config.json'
import url from 'url';
import ListingModal from './EditListingView'

// TODO: Update <Search> usage after its will be implemented

const ListingsView = () =>
{
    const [result, setResult] = useState({ isSearching: true, listings: [] });
    const [error, setError] = useState({ isError: false, message: '' });

    let useUpdate = () =>
    {
        useEffect(() =>
        {
            const getData = async () =>
            {
                let payload = { method: 'userListings' };

                axios({
                    method: 'get',
                    url: config.apiURL,
                    params: payload
                }).then(response =>
                {
                    console.log(response);
                    let listings = response.data.listings;
                    setResult({ isSearching: false, listings: listings });
                }).catch(error =>
                {
                    console.log(error);
                });
            };
            getData();
        }, []);
    };

    useUpdate();
    
    return (
        <div>
            <Menu attached='top'>
                <Dropdown item icon='settings' simple>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <Icon name='dropdown' />
                            <span className='text'>New</span>
                            <Dropdown.Menu>
                                <Dropdown.Item><CreateItemModal /></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown.Item>
                        <Dropdown.Item>Edit Permissions</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Header>Export</Dropdown.Header>
                        <Dropdown.Item>Share</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Menu position='right'>
                    <div className='ui right aligned category search item'>
                        <div className='ui transparent icon input'>
                            <input
                                className='prompt'
                                type='text'
                                placeholder='Search listings...'
                            />
                            <i className='search link icon' />
                        </div>
                        <div className='results' />
                    </div>
                </Menu.Menu>
            </Menu>

            <Segment attached='bottom'>
                <Grid centered>
                    <Grid.Row>
                        {!result.isSearching && !error.isError && result.listings && result.listings.length > 0 &&
                            result.listings.map((item, i) => 
                            {
                                // console.log(item);
                                if(item)
                                {
                                    return (
                                        // <ListingCardView name={item.name} description={item.description} image={item.image} dateCreated={item.dateCreated} score={item.score} />
                                        <ListingModal key={'id' + i} listing={item.Item} />
                                    );
                                }
                            })
                        }
                    </Grid.Row>
                </Grid>
                {(result.isSearching || result.isError) &&
                    <LoaderView text="Searching" />
                }
            </Segment>
        </div>
    );
}

export default ListingsView;