import React from 'react'
import { Card, Icon, Image } from 'semantic-ui-react'
import LoaderView from './LoaderView'

class ListingCardView extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            name: props.name,
            image: props.image,
            description: props.description,
            dateCreated: props.dateCreated,
            score: props.score,
        };
    }

    render()
    {
        return (
            <Card>
                {/* <Image src={this.state.image ? this.state.image : defaultImage} wrapped ui={false} /> */}
                
                { (!this.state.image || this.state.image === '') && <LoaderView text="Loading Image..." /> }
                { this.state.image && <Image size='medium' src={this.state.image} wrapped /> }

                <Card.Content>
                    <Card.Header>{this.state.name}</Card.Header>
                    <Card.Meta>
                        <span className='date'>Created on {this.state.dateCreated}</span>
                    </Card.Meta>
                    <Card.Description>{this.state.description}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <Icon name='like' /> {this.state.score}
                </Card.Content>
            </Card>
        );
    }
}

export default ListingCardView;