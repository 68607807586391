import React, { Component } from 'react';
import axios from 'axios';
import { Storage } from 'aws-amplify';
import { Form, Modal, Button } from 'semantic-ui-react';
import config from '../config.json'

class CreateItemModal extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {}
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e, { name, value })
    {
        this.setState({ [name]: value });
    }

    handleImageChange(e)
    {
        const file = e.target.files[0];
        this.setState({ image: file });
    }

    handleSubmit(event)
    {
        if(!this.state.image || !this.state.name || !this.state.description) return;

        const createListing = async () =>
        {
            let stateImage = this.state.image;
            let name = this.state.name;
            let description = this.state.description;
            let imageName = stateImage.name;
            await Storage.put(imageName, stateImage);
            const image = await Storage.get(imageName);

            let payload =
            {
                method: 'createListing',
                data:
                {
                    name: name,
                    description: description,
                    image: image
                }
            };
            
            axios({
                method: 'put',
                url: config.apiURL,
                params: payload
            }).then(response =>
            {
                console.log('added new listing. need to refresh');
                
            }).catch(error =>
            {
                console.log('createListing Error: ' + JSON.stringify(error));
                console.log(error);
            });
        };

        createListing();

        event.preventDefault();
        this.handleClose();
    }

    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false, name: '', description: '', image: null })
    
    render()
    {
        return (
            <Modal trigger={<Button onClick={this.handleOpen}>+ Add Plant</Button>} closeIcon={true} open={this.state.modalOpen} onClose={this.handleClose}>
                <Modal.Header>Add Plant</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group unstackable widths={2}>
                            <Form.Input name='name' label='Item Name' placeholder='Enter Item Name...' onChange={this.handleChange} value={this.state.name || ''} />
                            <Form.Input name='image' type="file" label='Image' placeholder='Enter file path...' onChange={this.handleImageChange} />
                        </Form.Group>
                        <Form.TextArea name='description' label='Item Description' placeholder='Add a Description of the Item...' onChange={this.handleChange} value={this.state.description || ''} />
                        <Form.Button type='submit'>Submit</Form.Button>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

export default CreateItemModal;